//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logout from '@/scripts/auth/logout.js';
export default {
name:"DeleteAccount",
data(){
    return {
        current_password: ''
    }
},
methods:{
     deleteAccount(){
        this.$q.dialog({
            title:'Confirm Delete Account',
            message: 'Are you sure you want to permanently delete your account?',
            cancel:true
        })
        .onOk(() => {
            this.payload = {
                id: this.$store.state.user.id,
                password: this.current_password
            }
            this.$store.dispatch('profile/DeleteAccount',this.payload)
            .then((r) => {
                if(r.status == 'success'){
                    this.$q.notify({
                        message:'Successfully deleted account.',
                        color:'primary'
                    })
                logout.execute(localStorage._uid, localStorage._at)
                this.$store.state.isLoggedIn = false;
                this.$store.commit('logout');
                this.$store.commit('user/logout');
                this.$router.push('/login');
                }
                 
            })
        })
    }
}
}
